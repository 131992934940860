// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../../../libs/Hooks.bs.js");
var $$Image = require("../Image/Image.bs.js");
var React = require("react");
var Carousel = require("../Carousel/Carousel.bs.js");
var Amplitude = require("../../../libs/Amplitude.bs.js");
var ReactIntl = require("react-intl");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AwsImageHandlerV5 = require("../../../libs/AwsImageHandlerV5.bs.js");
var JsxPPXReactSupport = require("rescript/lib/js/jsxPPXReactSupport.js");
var MoreVacationIdeas__Locations = require("./MoreVacationIdeas__Locations.bs.js");
var MoreVacationIdeasModuleScss = require("./MoreVacationIdeas.module.scss").default;

var css = MoreVacationIdeasModuleScss;

function MoreVacationIdeas$CarouselItem(props) {
  var onLoad = props.onLoad;
  var item = props.item;
  var elementRef = React.useRef(null);
  var isVisible = Hooks.useIsVisibleOnce(elementRef, undefined, undefined, undefined, undefined);
  React.useEffect(function () {
        Curry._1(onLoad, elementRef);
      });
  var source = React.createElement("source", {
        srcSet: "\n          " + AwsImageHandlerV5.buildImageUrl(Curry._1(AwsImageHandlerV5.ImageUrl.fromString, item.imageUrl), 300, 300, undefined, undefined, undefined) + ",\n          " + AwsImageHandlerV5.buildImageUrl(Curry._1(AwsImageHandlerV5.ImageUrl.fromString, item.imageUrl), 600, 600, undefined, undefined, undefined) + " 2x,\n          " + AwsImageHandlerV5.buildImageUrl(Curry._1(AwsImageHandlerV5.ImageUrl.fromString, item.imageUrl), 900, 900, undefined, undefined, undefined) + " 3x,\n        "
      });
  return React.createElement("a", {
              ref: Caml_option.some(elementRef),
              className: css.carouselItemContainer,
              href: item.url,
              target: "_self",
              onClick: (function (param) {
                  Amplitude.HomePageMoreVacationIdeas.vacationIdea(item.name);
                })
            }, React.createElement("picture", undefined, source, React.createElement($$Image.make, {
                      url: item.imageUrl,
                      fetchWidth: 300,
                      fetchHeight: 300,
                      alt: item.name,
                      withLoader: isVisible,
                      loading: /* Lazy */0
                    })), React.createElement("div", {
                  className: css.overlay
                }, React.createElement("p", undefined, item.name + " Vacation Rentals")));
}

var CarouselItem = {
  make: MoreVacationIdeas$CarouselItem
};

function MoreVacationIdeas(props) {
  var scrollingParams_width = 300;
  var scrollingParams_step = 300 + 50.0 * 3.0;
  var scrollingParams = {
    margin: 0.0,
    width: scrollingParams_width,
    step: scrollingParams_step,
    duration: 300,
    tolerance: 1.0
  };
  return React.createElement("section", {
              className: css.outerContainer
            }, React.createElement("div", {
                  className: css.innerContainer
                }, React.createElement("h3", {
                      className: css.header
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "more-vacation-ideas.title",
                          defaultMessage: "Top USA Destinations"
                        })), React.createElement(Carousel.make, {
                      items: MoreVacationIdeas__Locations.locations,
                      renderBlock: (function (item, onLoad) {
                          return JsxPPXReactSupport.createElementWithKey(item.name, React.Fragment, {
                                      children: React.createElement(MoreVacationIdeas$CarouselItem, {
                                            item: item,
                                            onLoad: onLoad
                                          })
                                    });
                        }),
                      compareEquality: (function (item1, item2) {
                          return item1.name === item2.name;
                        }),
                      scrollingParams: scrollingParams,
                      prevControlClassName: css.prevControlBtn,
                      nextControlClassName: css.nextControlBtn,
                      containerClassName: css.carouselContainer,
                      withScrollbar: false
                    })));
}

var carouselItemWidth = 300;

var carouselItemHeight = 300;

var make = MoreVacationIdeas;

var $$default = MoreVacationIdeas;

exports.css = css;
exports.carouselItemWidth = carouselItemWidth;
exports.carouselItemHeight = carouselItemHeight;
exports.CarouselItem = CarouselItem;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* css Not a pure module */
