// @flow
import * as React from 'react';
import _ from 'lodash/fp';

import { Form } from 'styleguide/components/forms';
import Autocomplete from 'styleguide/components/Autocopmlete/Autocomplete';
import { buildUrl } from 'libs/utils/api';
import * as Amplitude from 'app/rescript/libs/Amplitude.bs.js';
import { locationPath } from 'libs/utils/country';
import { saveAutocompleteLastLocationToCookie } from 'libs/utils/cookie';
import type { GeoLocation } from 'app/types';
import type { LocationSuggestion } from 'app/types/models';

import css from './Search.scss';

type Props = {
  placeholder?: string,
  contextualListingsPath: string,
  desktop: boolean,
  tablet: boolean,
  mobile: boolean,
  location?: string,
  notifyGtagOnSearch?: string => *,
  notifyGtagOnUpdate?: () => *,
  shouldHideUrlInstructions?: boolean,
  instructions?: React.Element<any>,
  notFoundMessage?: string,
  loadingMessage: string,
  hasFocusedSearchBar?: boolean,
  onFocusSearchBar?: (focusedOnSearchByCity: boolean) => void,
};

type State = {
  listingId: number,
  location: ?LocationSuggestion,
  searchTerm: ?string,
  geoLocation: ?GeoLocation,
  isSubmitting: boolean,
  forceShowDropdown: boolean,
};

export default class Search extends React.Component<Props, State> {
  state: State = {
    listingId: 0,
    location: null,
    searchTerm: null,
    geoLocation: null,
    isSubmitting: false,
    forceShowDropdown: false,
  };

  // $FlowIgnoreMe
  updateState = (key, value) => {
    // $FlowIgnoreMe
    this.setState({ [key]: value }, this.notifyGaOnFormUpdate);
  };

  onAutocompleteLocationChange = (
    location: ?LocationSuggestion,
    options?: { phrase: ?string, autoSelected: ?boolean },
  ) => {
    this.setState({ location }, () => {
      if (!options?.autoSelected) {
        this.handleSubmit(null, options?.phrase);
      }
    });
  };

  onSpecificListingChange = (listingId: number) => {
    this.setState({ listingId });
  };

  closeForcedDropdown = () => {
    this.setState({ forceShowDropdown: false });
  };

  handleSubmit = (event?: Event | DomEvent, searchTerm: ?string) => {
    const { listingId, location } = this.state;
    const locationName = location && location.name;

    const path = this.props.contextualListingsPath + locationPath(locationName);
    const query = {
      location: locationName,
      searchTerm,
    };

    if (listingId !== 0) {
      this.setState({ isSubmitting: true }, () => {
        window.location = buildUrl(`/listings/${listingId}`, query);
      });
    } else if (!location) {
      this.setState({ forceShowDropdown: true });
    } else {
      Amplitude.logEvent('Redirection from search navbar', {
        from: window.location.pathname,
        itemType: event ? 'search-button' : 'location',
        value: location.name,
      });
      this.setState({ forceShowDropdown: false });
      if (this.props.notifyGtagOnSearch && locationName) {
        this.props.notifyGtagOnSearch(locationName);
      }

      saveAutocompleteLastLocationToCookie(location);

      this.setState({ isSubmitting: true }, () => {
        window.location = buildUrl(path, query);
      });
    }
  };

  handleFocus = () => {
    if (this.props.onFocusSearchBar) {
      this.props.onFocusSearchBar(true);
    }
  };

  notifyGaOnFormUpdate = _.once(() => {
    if (this.props.notifyGtagOnUpdate) {
      this.props.notifyGtagOnUpdate();
    }
  });

  render = () => (
    <Form
      className="tw-w-full tw-flex tw-justify-center"
      onSubmit={this.handleSubmit}
      autoComplete="nope"
      preventDefault
    >
      <Autocomplete
        listingId={this.state.listingId}
        onLocationSelect={this.onAutocompleteLocationChange}
        onImmediateSearch={this.onAutocompleteLocationChange}
        onSpecificListingSearch={this.onSpecificListingChange}
        contextualListingsPath={this.props.contextualListingsPath}
        mobile={this.props.mobile}
        label="Search by City"
        inputClassName={css.phraseInput}
        dropdownClassName={css.dropdown}
        handleSubmit={this.handleSubmit}
        uiLocation="homepage"
        forceShowDropdown={this.state.forceShowDropdown}
        closeForcedDropdown={this.closeForcedDropdown}
        shouldHideUrlInstructions={this.props.shouldHideUrlInstructions}
        instructions={this.props.instructions}
        notFoundMessage={this.props.notFoundMessage}
        loadingMessage={this.props.loadingMessage}
        handleFocus={this.handleFocus}
      />
    </Form>
  );
}
