// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Context as ScreenSizeContext } from 'app/rescript/contexts/ScreenSizeContext.bs.js';
import * as gtag from 'libs/gtag';
import SearchListingExample from 'rescript/styleguide/components/SearchListingExample/SearchListingExample.bs.js';
import AirbnbIcon from 'rescript/styleguide/icons/AirbnbIcon.bs.js';
import BookingcomIcon from 'rescript/styleguide/icons/BookingcomIcon.bs.js';
import VrboIcon from 'rescript/styleguide/icons/VrboIcon.bs.js';
import HicheeAutocompleteSearch from 'styleguide/components/HicheeAutocompleteSearch/HicheeAutocompleteSearch';
import type { ExampleListing } from 'types';

import ScreenSize from 'app/enums/ScreenSize';
import { NAVBAR_HEIGHT } from 'app/styleguide/styles/misc/variables';

type Props = {
  contextualListingsPath: string,
  desktop: boolean,
  tablet: boolean,
  mobile: boolean,
  titleContent: ?string,
  exampleListing: ExampleListing,
  hasFocusedSearchBar: boolean,
  onFocusSearchBar: (focusedOnSearchByCity: boolean) => void,
  showSearchByCity: boolean,
  toggleSearchBar: () => void,
};

const SearchListing = (props: Props) => {
  // $FlowFixMe
  const searchBarRef = React.useRef(null);
  // $FlowFixMe
  const screen = React.useContext(ScreenSizeContext.x);

  const handleFocus = (focusedOnSearchByCity: boolean) => {
    if (screen === ScreenSize.SM || screen === ScreenSize.MD) {
      if (searchBarRef.current) {
        const boundaries = searchBarRef.current.getBoundingClientRect();
        window.scrollTo({
          top: boundaries.top + window.scrollY - NAVBAR_HEIGHT,
        });
      }
    }

    if (props.onFocusSearchBar) props.onFocusSearchBar(focusedOnSearchByCity);
  };

  return (
    <HicheeAutocompleteSearch
      contextualListingsPath={props.contextualListingsPath}
      desktop={props.desktop}
      tablet={props.tablet}
      mobile={props.mobile}
      instructions={<SearchListingExample exampleListing={props.exampleListing} />}
      notifyGtagOnSearch={(location: string) => {
        gtag.sendEvent({
          category: 'WelcomePage',
          action: 'Search Form Submission',
          label: location,
        });
      }}
      notifyGtagOnUpdate={() => {
        gtag.sendEvent({
          category: 'WelcomePage',
          action: 'Search Form Interaction',
        });
      }}
      loadingMessage="Searching for the listing and prices..."
      hasFocusedSearchBar={props.hasFocusedSearchBar}
      onFocusSearchBar={handleFocus}
    />
  );
};

const Provider = props => (
  <div className="tw-text-[14px] tw-leading-[24px] tw-text-neutral-800 tw-font-medium tw-flex tw-justify-center tw-items-center tw-gap-4">
    {props.children}
  </div>
);

const Hero = (props: Props) => (
  <div className="tw-flex tw-flex-col tw-items-center tw-py-[64px] md:tw-py-[96px] tw-px-16 md:tw-px-[30px] lg:tw-px-0 tw-gap-[40px] md:tw-gap-[60px]">
    <div id="providers-container" className="tw-flex tw-justify-between tw-max-w-[400px] tw-w-full">
      <Provider>
        <AirbnbIcon colors="General" /> Airbnb
      </Provider>
      <Provider>
        <BookingcomIcon colors="General" /> Booking.com
      </Provider>
      <Provider>
        <VrboIcon colors="General" /> Vrbo
      </Provider>
    </div>
    <div className="tw-flex tw-flex-col tw-gap-16 tw-items-center">
      <h1 className="tw-text-[30px] tw-leading-[39px] md:tw-text-[40px] md:tw-leading-[52px] lg:tw-text-[48px] lg:tw-leading-[62px] tw-font-semibold tw-text-neutral-800 tw-m-0">
        <FormattedMessage
          id="hero.header"
          defaultMessage="<primary>Compare</primary> Vacation Rental Prices"
          values={{
            primary: chunks => <span className="tw-text-primary-500">{chunks}</span>,
          }}
        />
      </h1>
      <span className="tw-text-[20px] tw-leading-[30px] tw-text-neutral-600 tw-text-center">
        <FormattedMessage
          id="hero.subheader"
          defaultMessage="Paste an Airbnb, Booking.com, or Vrbo listing web link (URL) and discover instantly where that property is listed for the best price."
        />
      </span>
    </div>
    <SearchListing {...props} />
  </div>
);

export default Hero;
