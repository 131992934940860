import { HeroExampleBox } from 'app/styleguide/components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const SaveYourMoney = ({ exampleListing }) => {
  if (!exampleListing) return null;

  return (
    <div className="tw-flex tw-flex-col tw-gap-[30px] md:tw-flex-row tw-justify-between tw-items-center tw-py-[64px] md:tw-py-[80px] lg:tw-px-0">
      <div className="tw-flex tw-flex-col tw-text-center md:tw-text-left tw-gap-16 md:tw-gap-[24px] lg:tw-gap-[52px] md:tw-max-w-[330px] lg:tw-max-w-[350px]">
        <h3 className="tw-text-neutral-800 tw-text-[21px] tw-leading-[32px] md:tw-text-[24px] md:tw-leading-[36px] lg:tw-text-[32px] lg:tw-leading-[42px] tw-font-semibold">
          <FormattedMessage
            id="welcomePage.saveYourMoney.heading"
            defaultMessage="<primary>Save money</primary> by using HiChee "
            values={{ primary: chunks => <span className="tw-text-primary-500">{chunks}</span> }}
          />
        </h3>
        <span className="tw-text-14 tw-leading-21 lg:tw-text-[16px] lg:tw-leading-24">
          <FormattedMessage
            id="welcomePage.saveYourMoney.subheading"
            defaultMessage="Airbnb, Booking.com and Vrbo are great for finding the perfect rental. But, they each charge different prices for the same property and the same dates. We help you save money by showing the best prices for each property and where best to book it."
          />
        </span>
      </div>
      <HeroExampleBox exampleListing={exampleListing} />
    </div>
  );
};

export default SaveYourMoney;
