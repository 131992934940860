// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Route = require("../../../Route.bs.js");
var React = require("react");
var Button = require("../Button/Button.bs.js");
var Carousel = require("../Carousel/Carousel.bs.js");
var ReactIntl = require("react-intl");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxPPXReactSupport = require("rescript/lib/js/jsxPPXReactSupport.js");
var TripListCollectionsPage = require("../../../bundles/trip-list-collections-index/TripListCollectionsPage.bs.js");

var scrollingParams_width = 344;

var scrollingParams_step = 344 + 50.0 * 3.0;

var scrollingParams = {
  margin: 0.0,
  width: scrollingParams_width,
  step: scrollingParams_step,
  duration: 300,
  tolerance: 1.0
};

function TripListsCollections$CarouselItem(props) {
  var onLoad = props.onLoad;
  var elementRef = React.useRef(null);
  React.useEffect(function () {
        Curry._1(onLoad, elementRef);
      });
  return React.createElement("div", {
              ref: Caml_option.some(elementRef),
              className: "tw-w-[300px] lg:tw-w-[344px]"
            }, React.createElement(TripListCollectionsPage.CollectionCard.make, {
                  collection: props.item
                }));
}

var CarouselItem = {
  make: TripListsCollections$CarouselItem
};

function TripListsCollections(props) {
  var collections = props.collections;
  if (collections.length !== 0) {
    return React.createElement("section", {
                className: "tw-py-[64px] lg:tw-pb-[100px] lg:tw-pt-[64px]"
              }, React.createElement("div", {
                    className: "tw-max-w-[1200px] tw-mx-auto"
                  }, React.createElement("h3", {
                        className: "tw-font-semibold tw-text-[20px] tw-leading-[26px] md:tw-text-[32px] md:tw-leading-[42px] tw-text-neutral-700 tw-mb-8 tw-mx-auto tw-w-fit"
                      }, React.createElement(ReactIntl.FormattedMessage, {
                            id: "trip-list-collections.title",
                            defaultMessage: "Popular Collections"
                          })), React.createElement("p", {
                        className: "tw-text-[14px] tw-leading-[21px] md:tw-text-[16px] md:tw-leading-[24px] tw-text-neutral-500 tw-mb-[32px] tw-mx-auto tw-w-fit"
                      }, React.createElement(ReactIntl.FormattedMessage, {
                            id: "trip-list-collections.subtitle",
                            defaultMessage: "Curated HiChee Trips for Every Taste"
                          })), React.createElement(Carousel.make, {
                        items: collections,
                        renderBlock: (function (item, onLoad) {
                            return JsxPPXReactSupport.createElementWithKey(item.name, React.Fragment, {
                                        children: React.createElement(TripListsCollections$CarouselItem, {
                                              item: item,
                                              onLoad: onLoad
                                            })
                                      });
                          }),
                        compareEquality: (function (item1, item2) {
                            return item1.name === item2.name;
                          }),
                        scrollingParams: scrollingParams,
                        prevControlClassName: "tw-absolute tw-m-0 tw-z-[1] disabled:tw-hidden tw-translate-x-1/2 tw-hidden lg:tw-block",
                        nextControlClassName: "tw-absolute tw-m-0 tw-z-[1] disabled:tw-hidden tw-translate-x-[-50%] tw-hidden lg:tw-block",
                        containerClassName: "tw-relative [&>div]:tw-flex [&>div]:tw-gap-[24px] [&>div]:tw-p-0 [&>div]:tw-overflow-x-scroll lg:[&>div]:tw-overflow-x-hidden",
                        withScrollbar: false
                      }), React.createElement("div", {
                        className: "tw-mt-[32px] tw-text-center"
                      }, React.createElement(Button.AsNativeLink.make, {
                            href: Route.collectionsPage(undefined),
                            size: /* MD */1,
                            color: /* Tertiary */2,
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "trip-lists-collections.button",
                                  defaultMessage: "See all Collections"
                                })
                          }))));
  } else {
    return null;
  }
}

var carouselItemWidth = 344;

var scrollingStepIncrement = 50.0;

var make = TripListsCollections;

exports.carouselItemWidth = carouselItemWidth;
exports.scrollingStepIncrement = scrollingStepIncrement;
exports.scrollingParams = scrollingParams;
exports.CarouselItem = CarouselItem;
exports.make = make;
/* Route Not a pure module */
