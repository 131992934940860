// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var H3 = require("../Typography/Heading/H3.bs.js");
var Span = require("../Typography/Span.bs.js");
var Vrbp = require("../../../models/Vrbp.bs.js");
var React = require("react");
var VrboIcon = require("../../icons/VrboIcon.bs.js");
var AirbnbIcon = require("../../icons/AirbnbIcon.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var BookingcomFullIcon = require("../../icons/BookingcomFullIcon.bs.js");
var JsxPPXReactSupport = require("rescript/lib/js/jsxPPXReactSupport.js");

var airbnbListingsCount = {
  id: "hichee-statistics.airbnb-listings-count",
  defaultMessage: "8M"
};

var bookingcomListingsCount = {
  id: "hichee-statistics.bookingcom-listings-count",
  defaultMessage: "7.8M"
};

var vrboListingsCount = {
  id: "hichee-statistics.vrbo-listings-count",
  defaultMessage: "2M"
};

var airbnbCountriesCount = {
  id: "hichee-statistics.airbnb-countries-count",
  defaultMessage: "220"
};

var bookingcomCountriesCount = {
  id: "hichee-statistics.bookingcom-countries-count",
  defaultMessage: "230"
};

var vrboCountriesCount = {
  id: "hichee-statistics.vrbo-countries-count",
  defaultMessage: "190"
};

var airbnbHostsCount = {
  id: "hichee-statistics.airbnb-hosts-count",
  defaultMessage: "5M+"
};

var listings = {
  id: "hichee-statistics.listing",
  defaultMessage: "listings"
};

var countriesAndRegions = {
  id: "hichee-statistics.countries-and-regions",
  defaultMessage: "countries & regions"
};

var countries = {
  id: "hichee-statistics.countries",
  defaultMessage: "countries"
};

var hosts = {
  id: "hichee-statistics.hosts",
  defaultMessage: "hosts"
};

var Messages = {
  airbnbListingsCount: airbnbListingsCount,
  bookingcomListingsCount: bookingcomListingsCount,
  vrboListingsCount: vrboListingsCount,
  airbnbCountriesCount: airbnbCountriesCount,
  bookingcomCountriesCount: bookingcomCountriesCount,
  vrboCountriesCount: vrboCountriesCount,
  airbnbHostsCount: airbnbHostsCount,
  listings: listings,
  countriesAndRegions: countriesAndRegions,
  countries: countries,
  hosts: hosts
};

function HiCheeStatistics__JsBridge$Header(props) {
  return React.createElement("div", {
              className: "tw-flex tw-flex-col tw-gap-[40px] md:tw-flex-row tw-justify-between tw-text-center md:tw-text-left tw-w-full"
            }, React.createElement("div", {
                  className: "tw-flex tw-flex-col tw-gap-16 lg:tw-pr-20"
                }, React.createElement(H3.make, {
                      className: "tw-text-[24px] tw-leading-[36px] lg:tw-text-[32px] lg:tw-leading-[42px] tw-text-neutral-800 tw-font-semibold",
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-statistics.heading",
                            defaultMessage: "Find the <primary>Best Price</primary>",
                            values: {
                              primary: (function (chuncks) {
                                  return React.createElement("span", {
                                              className: "tw-text-primary-500"
                                            }, chuncks);
                                })
                            }
                          })
                    }), React.createElement("span", {
                      className: "tw-text-[20px] tw-leading-[30px] tw-text-neutral-800"
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "hichee-statistics.subheading",
                          defaultMessage: "For Your Vacation Rental Booking"
                        }))), React.createElement("div", {
                  className: "md:tw-max-w-[330px] lg:tw-max-w-[350px]"
                }, React.createElement(Span.make, {
                      className: "lg:tw-text-[16px] lg:tw-leading-[24px]",
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-statistics.content",
                            defaultMessage: "HiChee monitors more than 18M properties worldwide, listed across Airbnb, Booking, and Vrbo. Track and Save your preferred listings to monitor price changes and find the best deals."
                          })
                    })));
}

var Header = {
  make: HiCheeStatistics__JsBridge$Header
};

function HiCheeStatistics__JsBridge$StatisticsContent$StatItem$Value(props) {
  return React.createElement("span", {
              className: "tw-font-semibold tw-text-neutral-800 tw-text-[24px] tw-leading-[42px]"
            }, props.children);
}

var Value = {
  make: HiCheeStatistics__JsBridge$StatisticsContent$StatItem$Value
};

function HiCheeStatistics__JsBridge$StatisticsContent$StatItem$Label(props) {
  return React.createElement("span", {
              className: "tw-text-neutral-600"
            }, props.children);
}

var Label = {
  make: HiCheeStatistics__JsBridge$StatisticsContent$StatItem$Label
};

function HiCheeStatistics__JsBridge$StatisticsContent$StatItem(props) {
  var intl = ReactIntl.useIntl();
  return React.createElement("div", undefined, React.createElement(HiCheeStatistics__JsBridge$StatisticsContent$StatItem$Value, {
                  children: intl.formatMessage(props.value)
                }), React.createElement("span", undefined, " "), React.createElement(HiCheeStatistics__JsBridge$StatisticsContent$StatItem$Label, {
                  children: intl.formatMessage(props.label)
                }));
}

var StatItem = {
  Value: Value,
  Label: Label,
  make: HiCheeStatistics__JsBridge$StatisticsContent$StatItem
};

function HiCheeStatistics__JsBridge$StatisticsContent$ProviderIcon(props) {
  switch (props.provider) {
    case /* Hichee */0 :
        return null;
    case /* Airbnb */1 :
        return React.createElement(AirbnbIcon.make, {
                    size: "XXL"
                  });
    case /* Bookingcom */2 :
        return React.createElement(BookingcomFullIcon.make, {
                    size: "XXL"
                  });
    case /* Vrbo */3 :
        return React.createElement(VrboIcon.make, {
                    size: "XXL"
                  });
    
  }
}

var ProviderIcon = {
  make: HiCheeStatistics__JsBridge$StatisticsContent$ProviderIcon
};

function HiCheeStatistics__JsBridge$StatisticsContent$OtaContent(props) {
  var data = props.data;
  var provider = data.provider;
  return React.createElement("div", {
              className: "tw-flex tw-flex-col tw-gap-16 tw-w-[206px]"
            }, React.createElement("div", {
                  className: "tw-flex tw-gap-12 tw-items-center tw-text-[20px] tw-leading-[30px] tw-text-neutral-800 tw-font-medium"
                }, React.createElement(HiCheeStatistics__JsBridge$StatisticsContent$ProviderIcon, {
                      provider: provider
                    }), Vrbp.Provider.toView(provider)), Belt_Array.mapWithIndex(data.statistics, (function (index, param) {
                    return JsxPPXReactSupport.createElementWithKey(String(index), HiCheeStatistics__JsBridge$StatisticsContent$StatItem, {
                                value: param.value,
                                label: param.label
                              });
                  })));
}

var OtaContent = {
  make: HiCheeStatistics__JsBridge$StatisticsContent$OtaContent
};

function HiCheeStatistics__JsBridge$StatisticsContent$Divider(props) {
  return React.createElement("div", {
              className: "tw-h-1 md:tw-h-auto tw-w-full md:tw-w-1 tw-bg-neutral-200 md:tw-my-16"
            });
}

var Divider = {
  make: HiCheeStatistics__JsBridge$StatisticsContent$Divider
};

function getProviderData(param) {
  return [
          {
            provider: /* Airbnb */1,
            statistics: [
              {
                value: airbnbListingsCount,
                label: listings
              },
              {
                value: airbnbCountriesCount,
                label: countriesAndRegions
              },
              {
                value: airbnbHostsCount,
                label: hosts
              }
            ]
          },
          {
            provider: /* Bookingcom */2,
            statistics: [
              {
                value: bookingcomListingsCount,
                label: listings
              },
              {
                value: bookingcomCountriesCount,
                label: countriesAndRegions
              }
            ]
          },
          {
            provider: /* Vrbo */3,
            statistics: [
              {
                value: vrboListingsCount,
                label: listings
              },
              {
                value: vrboCountriesCount,
                label: countries
              }
            ]
          }
        ];
}

function HiCheeStatistics__JsBridge$StatisticsContent(props) {
  var providerData = getProviderData(undefined);
  return React.createElement("div", {
              className: "tw-flex tw-flex-col tw-gap-[32px] tw-w-full"
            }, React.createElement(Span.make, {
                  fontWeight: /* Medium */2,
                  className: "tw-text-neutral-600 lg:tw-text-[16px] lg:tw-leading-[24px]",
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "hichee-statistics.date",
                        defaultMessage: "Sept 2024"
                      })
                }), React.createElement("div", {
                  className: "tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-justify-between tw-items-center md:tw-items-stretch tw-gap-[32px] md:tw-gap-0 tw-px-16 md:tw-px-0"
                }, Belt_Array.mapWithIndex(providerData, (function (index, data) {
                        return JsxPPXReactSupport.createElementVariadicWithKey(String(index), React.Fragment, {
                                    children: null
                                  }, [
                                    React.createElement(HiCheeStatistics__JsBridge$StatisticsContent$OtaContent, {
                                          data: data
                                        }),
                                    index < (providerData.length - 1 | 0) ? React.createElement(HiCheeStatistics__JsBridge$StatisticsContent$Divider, {}) : null
                                  ]);
                      }))));
}

var StatisticsContent = {
  StatItem: StatItem,
  ProviderIcon: ProviderIcon,
  OtaContent: OtaContent,
  Divider: Divider,
  getProviderData: getProviderData,
  make: HiCheeStatistics__JsBridge$StatisticsContent
};

function HiCheeStatistics__JsBridge$default(props) {
  return React.createElement("div", {
              className: "tw-flex tw-flex-col tw-gap-[40px] lg:tw-gap-[60px] tw-items-center tw-py-[64px] md:tw-py-[80px]"
            }, React.createElement(HiCheeStatistics__JsBridge$Header, {}), React.createElement(HiCheeStatistics__JsBridge$StatisticsContent, {}));
}

var $$default = HiCheeStatistics__JsBridge$default;

exports.Messages = Messages;
exports.Header = Header;
exports.StatisticsContent = StatisticsContent;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* H3 Not a pure module */
