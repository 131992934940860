// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../styleguide/components/Link/A.bs.js");
var Cx = require("rescript-classnames/src/Cx.bs.js");
var $$Date = require("../../libs/Date.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Route = require("../../Route.bs.js");
var React = require("react");
var Amplitude = require("../../libs/Amplitude.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AreYouAHostBox = require("../../styleguide/components/AreYouAHostBox/AreYouAHostBox.bs.js");
var AwsImageHandlerV5 = require("../../libs/AwsImageHandlerV5.bs.js");
var BackgroundVector1 = require("./components/BackgroundVector1.bs.js");
var BackgroundVector2 = require("./components/BackgroundVector2.bs.js");
var ScreenSizeContext = require("../../contexts/ScreenSizeContext.bs.js");
var JsxPPXReactSupport = require("rescript/lib/js/jsxPPXReactSupport.js");

function TripListCollectionsPage$CollectionsBanner(props) {
  var screen = props.screen;
  return React.createElement("div", {
              className: Cx.cx([
                    "tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-bg-neutral-50 tw-rounded-[10px] tw-overflow-hidden",
                    screen === "XS" || screen === "SM" ? "tw-h-fit tw-px-[32px] tw-py-[64px]" : "tw-h-[528px] tw-pl-[80px]"
                  ])
            }, React.createElement("div", {
                  className: Cx.cx([
                        "tw-relative tw-flex tw-flex-col tw-gap-[24px]",
                        screen === "XS" || screen === "SM" ? "" : "tw-max-w-[450px]"
                      ])
                }, screen === "XS" || screen === "SM" ? React.createElement(BackgroundVector1.make, {
                        className: "tw-absolute tw-w-[300px] tw-top-[-64px] tw-right-[-200px]"
                      }) : null, React.createElement("span", {
                      className: "tw-font-semibold tw-text-5xl tw-leading-[62px] tw-z-10"
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "collections.banner.header",
                          defaultMessage: "Explore Our{br}Collections",
                          values: {
                            br: React.createElement("br", undefined)
                          }
                        })), React.createElement("span", {
                      className: "tw-leading-[24px] tw-text-[16px]"
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "collections.banner.description",
                          defaultMessage: "Discover our curated collections, featuring <strong>Free Booking Platform Links</strong> for Airbnb, Booking.com, and Vrbo—all in one place. Join millions of travelers worldwide and unlock your wanderlust with HiChee Trips today, taking your travel experiences to new heights!",
                          values: {
                            strong: (function (chunks) {
                                return React.createElement("strong", undefined, chunks);
                              })
                          }
                        }))), screen === "XS" || screen === "SM" ? null : React.createElement("div", {
                    className: "tw-min-w-[750px] tw-h-full tw-relative"
                  }, React.createElement(BackgroundVector1.make, {
                        className: "tw-absolute tw-w-[417px] tw-left-[-20px]"
                      }), React.createElement(BackgroundVector2.make, {
                        className: "tw-absolute tw-w-[308px] tw-bottom-0 tw-right-0"
                      }), React.createElement("div", {
                        className: "tw-absolute tw-top-[-110px] tw-left-0 tw-w-[900px] tw-h-[465px] tw-rounded-full -tw-rotate-45 tw-overflow-hidden"
                      }, React.createElement("img", {
                            className: "tw-rotate-45 tw-h-[850px] tw-w-auto tw-absolute tw-top-[-170px] tw-left-[-190px]",
                            alt: "HiChee Collections",
                            src: AwsImageHandlerV5.buildImageUrl(Curry._1(AwsImageHandlerV5.ImageUrl.fromString, "images/static/collections-banner.jpeg"), undefined, undefined, undefined, undefined, undefined)
                          }))));
}

var CollectionsBanner = {
  make: TripListCollectionsPage$CollectionsBanner
};

function TripListCollectionsPage$CheckInCheckOut(props) {
  return React.createElement("div", {
              className: "tw-flex tw-flex-row tw-gap-4"
            }, "" + $$Date.Naive.format(props.checkIn, "MMM d, yyyy") + " – " + $$Date.Naive.format(props.checkOut, "MMM d, yyyy") + "");
}

var CheckInCheckOut = {
  make: TripListCollectionsPage$CheckInCheckOut
};

function TripListCollectionsPage$CollectionCard(props) {
  var collection = props.collection;
  var match = collection.checkIn;
  var match$1 = collection.checkOut;
  return React.createElement(A.Unstyled.make, {
              href: Route.collectionPage(collection.slug, ""),
              onClick: (function (param) {
                  Amplitude.Collection.clickedCollection(collection.slug);
                }),
              testId: "collection-card-" + collection.slug + "",
              children: React.createElement("div", {
                    className: "tw-flex tw-flex-col tw-gap-12 tw-rounded-[10px]"
                  }, React.createElement("img", {
                        className: "tw-h-[300px] tw-rounded-[10px] tw-object-cover tw-object-left-bottom",
                        alt: collection.name,
                        loading: "lazy",
                        src: collection.coverImageUrl
                      }), React.createElement("div", {
                        className: "tw-flex tw-flex-col tw-gap-4"
                      }, React.createElement("span", {
                            className: "tw-font-semibold tw-text-[16px]"
                          }, collection.name), match !== undefined && match$1 !== undefined ? React.createElement("div", {
                              className: "tw-flex tw-flex-row tw-gap-4 tw-text-neutral-500 tw-font-medium"
                            }, React.createElement(TripListCollectionsPage$CheckInCheckOut, {
                                  checkIn: Caml_option.valFromOption(match),
                                  checkOut: Caml_option.valFromOption(match$1)
                                }), "•", React.createElement(ReactIntl.FormattedMessage, {
                                  id: "collections.card.adults",
                                  defaultMessage: "{adults, plural, =1{# Adult} other{# Adults}}",
                                  values: {
                                    adults: collection.adults
                                  }
                                })) : null))
            });
}

var CollectionCard = {
  make: TripListCollectionsPage$CollectionCard
};

function TripListCollectionsPage$CollectionsSection(props) {
  var screen = props.screen;
  var description = props.description;
  return React.createElement("div", {
              className: "tw-flex tw-flex-col tw-gap-[24px]"
            }, React.createElement("div", {
                  className: "tw-flex tw-flex-col"
                }, React.createElement("span", {
                      className: "tw-font-semibold tw-text-xl"
                    }, props.title), description !== undefined ? React.createElement("span", {
                        className: "tw-text-neutral-500"
                      }, description) : null), React.createElement("div", {
                  className: Cx.cx([
                        "tw-grid tw-gap-x-[16px] tw-gap-y-[32px]",
                        screen === "MD" ? "tw-grid-cols-2" : (
                            screen === "XS" || screen === "SM" ? "tw-grid-cols-1" : "tw-grid-cols-3"
                          )
                      ])
                }, Belt_Array.map(props.collections, (function (collection) {
                        return JsxPPXReactSupport.createElementWithKey(collection.slug, TripListCollectionsPage$CollectionCard, {
                                    collection: collection
                                  });
                      }))));
}

var CollectionsSection = {
  make: TripListCollectionsPage$CollectionsSection
};

function TripListCollectionsPage(props) {
  var screen = React.useContext(ScreenSizeContext.Context.x);
  var intl = ReactIntl.useIntl();
  return React.createElement("div", {
              className: Cx.cx([
                    "tw-flex tw-flex-col tw-gap-[72px] tw-pt-[24px] tw-pb-[64px] tw-max-w-[1440px] tw-mx-auto",
                    screen === "MD" ? "tw-px-[32px]" : (
                        screen === "XS" || screen === "SM" ? "tw-px-[24px]" : "tw-px-[80px]"
                      )
                  ])
            }, React.createElement(TripListCollectionsPage$CollectionsBanner, {
                  screen: screen
                }), React.createElement(TripListCollectionsPage$CollectionsSection, {
                  collections: props.collections,
                  title: intl.formatMessage({
                        id: "collections.section.popular.title",
                        defaultMessage: "Popular Collections"
                      }),
                  description: intl.formatMessage({
                        id: "collections.section.popular.description",
                        defaultMessage: "Curated Selections for Every Taste"
                      }),
                  screen: screen
                }), React.createElement(AreYouAHostBox.make, {
                  wrapperClassName: "tw-z-auto"
                }));
}

var make = TripListCollectionsPage;

exports.CollectionsBanner = CollectionsBanner;
exports.CheckInCheckOut = CheckInCheckOut;
exports.CollectionCard = CollectionCard;
exports.CollectionsSection = CollectionsSection;
exports.make = make;
/* A Not a pure module */
