// @flow

// $FlowFixMe
import React, { useEffect, useState } from 'react';
import _ from 'lodash/fp';
import { connect } from 'react-redux';
import { parse } from 'qs';
import HiddenListingModal from 'app/rescript/styleguide/components/HiddenListingModal/HiddenListingModal.bs.js';
import withModalDialogProp from 'libs/hocs/withModalDialogProp';

import {
  openSignUpModal,
  sendSessionEmailForExistingUser,
  openInvalidTokenModal,
  openResendEmailModal,
  openMagicLinkModal,
} from 'layout/actions/authenticationModalActions';

import WelcomePage from '../components/WelcomePage';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isUserLoggedIn: !_.isNil(state.$$navbarStore.get('user')),
  userEmail: _.isNil(state.$$navbarStore.get('user')) ? null : state.$$navbarStore.getIn(['user', 'email']),
});

const removeTokensFromUrl = () => {
  const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');
  const newSearchParams = new URLSearchParams();
  if (redirectUrl) newSearchParams.set('redirectUrl', redirectUrl);

  // Remove url query params except redirectUrl without pushing history and reloading page
  window.history.replaceState(
    null,
    document.title,
    `${window.location.pathname}?${newSearchParams.toString()}`,
  );
};

const getQueryParamsFromUrl = () => {
  const isClientSide = typeof window !== 'undefined';
  return isClientSide ? parse(window.location.search, { ignoreQueryPrefix: true }) : {};
};

const WelcomePageContainer = props => {
  const [hasFocusedSearchBar, setHasFocusedSearchBar] = useState(false);
  const [showSearchByCity, setShowSearchByCity] = useState(false);

  const handleResendEmailToken = token => {
    props.openResendEmailModal(token);
    removeTokensFromUrl();
  };

  const handleShouldAuthenticateToken = () => {
    if (!props.isUserLoggedIn) {
      props.openSignUpModal();
    }

    removeTokensFromUrl();
  };

  const handleLoginWithMagicLink = token => {
    props.openMagicLinkModal(token, response => {
      if (response.redirectUrl) {
        window.location.href = response.redirectUrl;
      }
    });
    removeTokensFromUrl();
  };

  const handleHiddenListing = () => {
    props.modalDialog.show();
    removeTokensFromUrl();
  };

  useEffect(() => {
    const params = getQueryParamsFromUrl();

    if (!_.isNil(params.resend_email)) handleResendEmailToken(params.resend_email);

    if (!_.isNil(params.should_authenticate)) handleShouldAuthenticateToken();

    if (!_.isNil(params.magic_link)) handleLoginWithMagicLink(params.magic_link);

    if (!_.isNil(params.hidden_listing)) handleHiddenListing();
  }, []);

  const handleFocusSearchBar = (focusedOnSearchByCity: boolean) => {
    setHasFocusedSearchBar(true);
    setShowSearchByCity(focusedOnSearchByCity);
  };

  const toggleSearchBar = () => {
    setShowSearchByCity(prev => !prev);
  };

  return (
    <>
      <WelcomePage
        {...props}
        hasFocusedSearchBar={hasFocusedSearchBar}
        onFocusSearchBar={handleFocusSearchBar}
        showSearchByCity={showSearchByCity}
        onToggleSearchBar={toggleSearchBar}
      />

      <HiddenListingModal modal={props.modalDialog} onHide={props.modalDialog.hide} />
    </>
  );
};

export default connect(
  mapStateToProps,
  {
    openSignUpModal,
    sendSessionEmailForExistingUser,
    openInvalidTokenModal,
    openResendEmailModal,
    openMagicLinkModal,
  },
)(withModalDialogProp(WelcomePageContainer));
