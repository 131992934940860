// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { AreYouAHostBox } from 'styleguide/components';
import type { ExampleListing } from 'app/types';
import type { NavbarProps, Collection } from 'layout/types';

import MoreVacationIdeas from 'rescript/styleguide/components/MoreVacationIdeas/MoreVacationIdeas.bs.js';
import TripListsCollections from 'rescript/styleguide/components/TripListCollections/TripListsCollections__JsBridge.bs.js';
import HiCheeStatistics from 'rescript/styleguide/components/HiCheeStatistics/HiCheeStatistics__JsBridge.bs.js';
import ExtensionPromoCard from 'rescript/styleguide/components/ExtensionPromoCard/ExtensionPromoCard.bs.js';
import TripListsPromoCard from 'rescript/styleguide/components/TripListsPromoCard/TripListsPromoCard.bs.js';
import HicheeDifferent from 'rescript/styleguide/components/HiCheeDifferent/HiCheeDifferent.bs.js';
import GoogleAd from 'app/rescript/styleguide/components/GoogleAd/GoogleAd__JsBridge.bs.js';
import ShakaCodeDataAd from 'app/rescript/styleguide/components/ShakaCodeDataAd/ShakaCodeDataAd__JsBridge.bs.js';
import { Container as WelcomeContainer } from 'rescript/styleguide/components/WelcomePageComponents/WelcomePageComponents.bs.js';
import cn from 'classnames';
import Hero from './Hero/Hero';
import css from './WelcomePage.scss';
import TrustBanner from './TrustBanner/TrustBanner';
import WelcomePageStructuredData from './WelcomePageStructuredData/WelcomePageStructuredData';
import SaveYourMoney from './SaveYourMoney/SaveYourMoney';

const PAGE_SEO_DATA = {
  headingContent: 'Don’t overpay. Check that\nAirbnb before you book it!',
  pageTitle: 'HiChee | Check and Compare Airbnb, Booking, and Vrbo Vacation Rental Prices',
  previewTitle: 'HiChee | Don’t overpay, check that Airbnb before you book it!',
  keyword: '',
  description:
    'Search around 14 million vacation rental deals in just one click! Just paste the link ' +
    'from Airbnb / Vrbo / Booking and see all prices, including from the direct host.',
};

type Props = {
  contextualListingsPath: string,
  desktop: boolean,
  tablet: boolean,
  mobile: boolean,
  chrome: boolean,
  isUserLoggedIn: boolean,
  openSignUpModal: () => void,
  sendMagicLink: (data: { email: string }, callback: { onSuccess?: Function, onFailure?: Function }) => void,
  openInvalidTokenModal: () => void,
  slug: string | null,
  exampleListing: ExampleListing,
  hasFocusedSearchBar: boolean,
  onFocusSearchBar: (focusedOnSearchByCity: boolean) => void,
  showSearchByCity: boolean,
  onToggleSearchBar: () => void,
  fansVideos: Array<string>,
  navbar: NavbarProps,
  collections: Array<Collection>,
};

const Container = WelcomeContainer.make;

const WelcomePage = (props: Props) => {
  const { contextualListingsPath, desktop, tablet, mobile, exampleListing, collections } = props;

  return (
    <main className={css.WelcomePage}>
      <Helmet preserved>
        <title>{PAGE_SEO_DATA.pageTitle}</title>
        <meta name="keywords" content={PAGE_SEO_DATA.keyword} />
        <meta name="description" content={PAGE_SEO_DATA.description} />
        <meta property="og:title" content={PAGE_SEO_DATA.previewTitle} />
        <meta property="twitter:title" content={PAGE_SEO_DATA.previewTitle} />
      </Helmet>
      <WelcomePageStructuredData />
      <Container size="Narrow">
        <Hero
          contextualListingsPath={contextualListingsPath}
          desktop={desktop}
          tablet={tablet}
          mobile={mobile}
          titleContent={PAGE_SEO_DATA.headingContent}
          exampleListing={exampleListing}
          hasFocusedSearchBar={props.hasFocusedSearchBar}
          onFocusSearchBar={props.onFocusSearchBar}
          toggleSearchBar={props.onToggleSearchBar}
          showSearchByCity={props.showSearchByCity}
        />
      </Container>
      <Container size="Wide">
        <TrustBanner />
      </Container>
      <Container size="Narrow">
        <HiCheeStatistics />
        <SaveYourMoney exampleListing={exampleListing} mobile={mobile} />
      </Container>
      <Container size="Wide">
        <div className="tw-flex tw-flex-col tw-gap-[50px] tw-py-[64px] md:tw-py-[80px]">
          <TripListsPromoCard location="HomePage" />
          <ExtensionPromoCard location="HomePage" />
        </div>
      </Container>
      <Container size="Narrow">
        <HicheeDifferent />
        <div
          className={cn(css.sectionWrapper, 'tw-flex tw-flex-col tw-gap-[48px] tw-py-[64px] md:tw-py-[80px]')}
        >
          <GoogleAd
            slot="2821768523"
            format="auto"
            fullWidthResponsive
            wrapperClassName={css.homeBottomAdDesktop}
            restrictTo="desktop-only"
            userSubscription={props.navbar?.user?.subscription}
          />
          <GoogleAd
            slot="3456193346"
            format="auto"
            fullWidthResponsive
            wrapperClassName={css.homeBottomAdMobile}
            restrictTo="mobile-only"
            userSubscription={props.navbar?.user?.subscription}
          />
          <ShakaCodeDataAd />
        </div>
      </Container>
      <Container size="Wide">
        <div className="tw-py-[64px] md:tw-py-[80px]">
          <AreYouAHostBox />
        </div>
        <TripListsCollections collections={collections} />
        <MoreVacationIdeas />
      </Container>
    </main>
  );
};

export default WelcomePage;
